<template>
<div class="statistics">
  <div class="statistics-cont mt20">
    <div class="statistics-tit">师资增量统计</div>
    <div class="search">
      <div class="search-item">
        <div class="name">
          时间:
        </div>
        <div class="cont">
          <div class="values">
            <div :class="dateType == item.id ? 'active' : ''" v-for="(item,index) in dateTypeList" :key="item.id" @click="changeSearch(item.id,'dateType')">{{item.name}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="statistics-chart" id="statistics-chart"></div>
  </div>

</div>
</template>

<script>
export default {
  name: "statistics.vue",
  data(){
    return{
      dateTypeList:[],
      dateType:'',
    }
  },
  mounted(){
    this.getData();
  },
  methods:{
    changeSearch(data,name){
      if(data == this[name]) return;
      this[name] = data;
      this.getData('search');
    },
    getData(type){
      let params = {
        date_type:this.dateType
      }
      this.api.user.teacherStat(params).then((res)=>{
        let xAxisData = res.list.map((item)=>{
          return item.date
        });
        let seriesData = res.list.map((item)=>{
          return item.count
        });
        this.setOption(xAxisData,seriesData);
        this.dateTypeList = [];

        for(let name in res.dateTypes){
          this.dateTypeList.push({
            name:res.dateTypes[name],
            id:name
          })
        }
        if(type != 'search'){
          this.dateType = this.dateTypeList[0].id
        }
      })
    },
    setOption(xAxisData,seriesData){
      var chartDom = document.getElementById('statistics-chart');
      var myChart = this.$echarts.init(chartDom);
      var option;

      option = {
        // title: {
        //   text: '师资注册量统计'
        // },
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data:xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: seriesData,
            type: 'line'
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
}
</script>

<style scoped lang="scss">
.statistics{
  margin: 20px;
  font-size: 14px;
  .statistics-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
  }
  .statistics-tit{
    font-size: 18px;
    font-weight: bold;
  }
  .statistics-chart{
    width: 100%;
    height: 400px;
  }

  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
</style>
